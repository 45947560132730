import React, { ReactNode } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

interface FaqsProps {
    data: Array<{
        question: string;
        answer: ReactNode;
    }>;
}

export const Faqs = ({ data }: FaqsProps) => {
    return (
        <dl>
            {(data || []).map((faq) => (
                <Disclosure
                    as={'div'}
                    key={faq.question}
                    className={'py-5 border-b border-b-gray-300 bg-neutrals-100 w-full'}
                >
                    {({ open }) => (
                        <>
                            <dt className={'text-lg w-full'}>
                                <Disclosure.Button className={'text-left w-full flex justify-between items-start'}>
                                    <span className={'text-[16px] md:text-[18px]'}>{faq.question}</span>
                                    <span className={'ml-6 h-7 flex items-center'}>
                                        <ChevronDownIcon
                                            className={classNames(
                                                open ? '-rotate-180' : 'rotate-0',
                                                'h-6 w-6 transform'
                                            )}
                                            aria-hidden={'true'}
                                        />
                                    </span>
                                </Disclosure.Button>
                            </dt>
                            <Disclosure.Panel as={'dd'} className={'mt-2 pt-5 pr-12 w-full'}>
                                <p className={'text-base text-[16px] md:text-[18px]'}>{faq.answer}</p>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            ))}
        </dl>
    );
};
