import { QueryClient, useQuery } from 'react-query';

import { queries } from '~/constants/queryKeys';
import {
    VinovestNetworkHelperAuthenticationParams,
    VinovestNetworkingHelper
} from '~/helpers/VinovestNetworkingHelper';

import { useAuthentication } from '../useAuthentication';

export const UserPreferencesQuery = {
    load: async (auth: VinovestNetworkHelperAuthenticationParams) => {
        const { managed } = VinovestNetworkingHelper.getClient(auth);
        return managed.getUserPreferences();
    },
    queryKey: (userId: string) => [queries.getUserPreferences, userId],
    prefetch: async (auth: VinovestNetworkHelperAuthenticationParams, queryClient: QueryClient, userId: string) => {
        const preferences = await UserPreferencesQuery.load(auth);
        queryClient.setQueryData(UserPreferencesQuery.queryKey(userId), preferences);
    }
};

export const useUserPreferences = () => {
    const { userId } = useAuthentication(true);
    return useQuery(UserPreferencesQuery.queryKey(userId ?? ''), async () => {
        // if (!isAuthenticated) {
        //     return 'Unable to update preferences at this time.';
        // }

        return UserPreferencesQuery.load({ userId });
    });
};
