import Link from 'next/link';

export const preFundedFAQs = [
    {
        question: 'How much will my deposit get me? ',
        answer: `The amount of wine you receive depends on several factors, including the time of year, your deposit amount, and your time 
        horizon. A typical $5,000 portfolio will have 5 to 8 unique cases of wine.`
    },
    {
        question: 'Why is the minimum deposit $1,000?',
        answer: `Vinovest has strict criteria for fine wine. If the minimum were lower, the wine wouldn’t be investment-worthy. $1,000 gives 
        you the flexibility to start your investing journey with 1 to 2 cases of wine while covering storage, insurance, and authentication costs.`
    },
    {
        question: 'Why and how does wine increase in value?',
        answer: `Fine wine prices increase for two primary reasons. First, fine wine is produced in limited quantities and develops 
        desirable characteristics as it ages. This causes demand to increase as the wine matures. Second, people drink the wine, reducing 
        the already finite supply. These two factors contributed to the fine wine appreciating more than 400% since 2004.`
    },
    {
        question: 'How does Vinovest guarantee authenticity and storage quality?',
        answer: `Vinovest takes care of the heavy lifting so you don’t have to. We employ rigorous authentication methods to ensure 
        the provenance of every case. We also partner with world-class storage facilities across three continents to store your assets 
        under optimal conditions with peace of mind guaranteed.`
    },
    {
        question: 'When and how do I sell my wine?',
        answer: (
            <>
                Vinovest will sell your wine when it reaches maturity - a process that takes 7 to 10+ years. If you
                expect to hold your wine for fewer than 5 years, wine investing may not be right for you. You may prefer
                whiskey which usually takes 2 to 4 years to reach maturity. <br />
                <br />
                When your wine reaches maturity, our team leverages its global network of wine shops, high-end
                restaurants, and private collectors to get you the highest price.
            </>
        )
    },
    {
        question: 'How can I learn more about wine investing?',
        answer: (
            <>
                At Vinovest, we aim to grow your wealth over the long term and educate you along the way. Our team hosts
                interactive webinars and office hours to help you level up your investing knowledge. You can also check
                out the{' '}
                {
                    <Link href={'/community'} className={'text-brown-600'}>
                        Vinovest Community
                    </Link>
                }{' '}
                to discuss, learn, and connect with other Vinovestors.
            </>
        )
    }
];

export const sharedFAQs = [
    {
        question: 'Why do I need to hang tight?',
        answer: `The entire buying process takes 2 to 3 weeks. Once your first deposit lands in your Vinovest account, our algorithm begins actively looking for fine wine that fits your investment criteria. We always try to purchase wine below the retail price to ensure the maximum possible return on investment. After that, we authenticate, ship, and store the bottles at the nearest bonded warehouse.`
    },
    {
        question: 'Can I get discounts on fees?',
        answer: `Yes! If a friend funds a Vinovest account through your referral link (in the “Invite” tab on your portfolio) then you both get 3 months of no fees. You will receive 5% off all management fees as long as you have Auto Invest active on a monthly cadence. 
        It is up to the discretion of the portfolio advisor if they want to offer X months with no fees to close an opportunity.`
    },
    {
        question: 'What is the insurance policy on my bottles?',
        answer: `Vinovest also has a comprehensive third-party insurance policy that will protect your wines at full current market value in the unlikely event that a natural disaster hits the storage facility, or an employee damages the wines.`
    },
    {
        question: 'What happens to my bottles if Vinovest goes out of business?',
        answer: `Because you own the underlying assets (the bottles of wine), you will still be able to collect on them if Vinovest goes out of business. If the worst-case scenario unfolds and Vinovest goes under, your investment is still secure.
        The storage facilities would be notified to reach out to direct owners of the wine (you), and you could continue to store it there, ship it to yourself, or liquidate it via auction.`
    }
];
