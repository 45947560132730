import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useManagedPortfolioQuery } from '~/hooks/queries/portfolios/managed/useManagedPortfolioQuery';
import { useAuthentication } from '~/hooks/useAuthentication';
import { baseRoutes } from '~/routes/base';

/**
 * Redirects new user to fill in managed quiz to setup portfolio
 */
export const useRedirectNewUserNoPortfolio = () => {
    useAuthentication(true);
    const { data: portfolioData } = useManagedPortfolioQuery();
    const router = useRouter();

    useEffect(() => {
        if (portfolioData && portfolioData.code === 401 && portfolioData.message === 'Could not find portfolio') {
            router.push(baseRoutes.onboarding.href);
        }
    }, [portfolioData]);
};
