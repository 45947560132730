import React, { useContext, useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import ReactPlayer from 'react-player';
import { useQueryClient } from 'react-query';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import howVinovestWorks from '~/assets/img/marketing/hand-shake.svg';
import transfer from '~/assets/img/marketing/transfer-2.svg';
import VideoDesktopThumbnail from '~/assets/img/marketing/Video_wine-dt-thumbnail.jpg';
import VideoMobileThumbnail from '~/assets/img/marketing/Video_wine-mo-thumbnail.jpg';
import howWineInvestingWorks from '~/assets/img/marketing/world.svg';
import { AddFundsInitiators, AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { DepositContext } from '~/context/Deposit/DepositContext';
import { PosthogHelper } from '~/helpers/PosthogHelper';
import { DepositTransferModalKey, UrlModalHelper } from '~/helpers/UrlModalHelper';
import { useUserPreferences } from '~/hooks/account/useUserPreferences';
import { useRedirectNewUserNoPortfolio } from '~/hooks/managed/useRedirectNewUserNoPortfolio';
import { useManagedPortfolioQuery } from '~/hooks/queries/portfolios/managed/useManagedPortfolioQuery';
import { useTradingPortfolioQuery } from '~/hooks/queries/portfolios/trading/useTradingPortfolioQuery';
import {
    PendingTransactionsQuery,
    usePendingPortfolioTransactions
} from '~/hooks/queries/portfolios/usePendingPortfolioTransactions';
import { useTracking } from '~/hooks/tracking/useTracking';
import { useAuthentication } from '~/hooks/useAuthentication';
import { PortfolioTypes } from '~/models/PortfolioType';
import { baseRoutes } from '~/routes/base';
import { Box, Button, Card, Flex, Grid, Heading, List, ListItem, Text } from '~/vinovest-design-system/components';

import { Faqs } from './components/Faqs';
import { TryWhiskeyCard } from './components/TryWhiskey';
import { preFundedFAQs } from './shared/faqs';
import { PlanBookACallEmbed } from '../../Onboarding/PlanPortfolio/components/BookACall/PlanBookACallEmbed';

export const PreDepositPortfolio = () => {
    useRedirectNewUserNoPortfolio();
    const router = useRouter();
    const { setDestinationPortfolio } = useContext(DepositContext);
    const { data: managedPortfolio, isSuccess } = useManagedPortfolioQuery();
    const { data: tradingPortfolio } = useTradingPortfolioQuery();
    const { data: preferences } = useUserPreferences();
    const { userId } = useAuthentication();
    const { refetch } = usePendingPortfolioTransactions(userId);
    const { data: managed } = useManagedPortfolioQuery();
    const queryClient = useQueryClient();
    const { captureEvent } = useTracking();

    useEffect(() => {
        const depositInitiated = async () => {
            await queryClient.invalidateQueries(PendingTransactionsQuery.queryKey(userId, userId));
            await queryClient.refetchQueries(PendingTransactionsQuery.queryKey(userId, userId));
            await queryClient.invalidateQueries(PendingTransactionsQuery.queryKey(managed?.id, managed?.id));
            await queryClient.refetchQueries(PendingTransactionsQuery.queryKey(managed?.id, managed?.id));
        };
        window.addEventListener(AnalyticsEventNames.DepositInitiated, depositInitiated);
        return () => window.removeEventListener(AnalyticsEventNames.DepositInitiated, depositInitiated);
    }, [managed?.id]);

    useEffect(() => {
        if (
            managedPortfolio &&
            managedPortfolio.code === 401 &&
            managedPortfolio.message === 'Could not find portfolio'
        ) {
            router.push(baseRoutes.onboarding.href);
        }
    }, [managedPortfolio]);

    useEffect(() => {
        refetch();
    }, [managedPortfolio]);

    const minWineDeposit = useMemo(() => {
        return (
            managedPortfolio?.cash && managedPortfolio?.cash?.amount + managedPortfolio?.positionTotal.amount <= 1000
        );
    }, [managedPortfolio]);

    const handleAddFunds = (location: string) => {
        setDestinationPortfolio(PortfolioTypes.managed);
        UrlModalHelper.openModal(router, DepositTransferModalKey, minWineDeposit ? '/managed/offer/wine-offer' : '');
        captureEvent(AnalyticsEventNames.AddFunds, {
            initiator: AddFundsInitiators.OverviewHero,
            location
        });
    };

    const videoID = isMobile ? '883095349' : '883094659';
    const videoThumb = isMobile ? VideoMobileThumbnail : VideoDesktopThumbnail;
    const thumbnailWidth = isMobile ? '281' : '1024';
    const thumbnailHeight = isMobile ? '485' : '576';

    const preferenceInvestmentAmount =
        (preferences ? preferences?.data?.quizQuestions?.howMuchInvesting?.answer : undefined) || undefined;
    const accountBalances = useMemo<{ managedBalance: number; tradingBalance: number; totalBalance: number }>(() => {
        const managedBalance =
            (!!managedPortfolio && isSuccess
                ? managedPortfolio?.cash?.amount + managedPortfolio?.positionTotal?.amount
                : 0) || 0;
        const managedBalanceDollars = managedBalance / 100;
        const tradingBalanceDollars =
            ((tradingPortfolio?.totalCashValue ?? 0) + (tradingPortfolio?.bottleValue || 0)) / 100;
        const totalBalance =
            (!Number.isNaN(managedBalanceDollars + tradingBalanceDollars) &&
                managedBalanceDollars + tradingBalanceDollars) ||
            0;
        return { managedBalance: managedBalanceDollars, tradingBalance: tradingBalanceDollars, totalBalance };
    }, [
        managedPortfolio?.cash?.amount,
        managedPortfolio?.positionTotal?.amount,
        tradingPortfolio?.totalCashValue,
        tradingPortfolio?.bottleValue
    ]);

    const bookACallSection =
        accountBalances &&
        accountBalances.totalBalance === 0 &&
        preferenceInvestmentAmount &&
        preferenceInvestmentAmount !== 'lessThan5k' ? (
            <Box classnames={'mb-5'}>
                <Card
                    shadow={'none'}
                    borderRadius={'lg'}
                    classnames={'bg-neutrals-100 border border-neutrals-200 p-4 md:p-0'}
                >
                    <Flex classnames={'flex-col md:flex-row px-0 md:px-12 h-auto md:h-auto items-stretch'}>
                        <PlanBookACallEmbed useType={'wine'} />
                    </Flex>
                </Card>
            </Box>
        ) : undefined;

    return (
        <>
            <Box classnames={'mb-5'}>
                <Card shadow={'none'} borderRadius={'lg'} classnames={'bg-neutrals-100 border border-neutrals-200 p-0'}>
                    <Flex classnames={'flex-col md:flex-row px-0 md:px-12 h-auto md:h-auto lg:h-80 items-stretch'}>
                        <Box classnames={'self-center w-full md:w-auto order-1 text-center md:text-left py-8'}>
                            <Heading
                                level={'h1'}
                                type={'h2'}
                                classnames={'normal-case leading-tight max-w-[250px] m-auto md:m-0 pt-3'}
                            >
                                Start Your Personalized <span className={'text-brown-600'}>Wine</span> Portfolio
                            </Heading>
                        </Box>
                        <Box classnames={'relative w-[60%] h-auto mx-auto md:w-[436px] order-2 md:h-[320px] mt-0'}>
                            <Box classnames={'md:absolute md:bottom-0 mb-0 md:-mb-2 h-auto'}>
                                <Image src={transfer} alt={'Make a deposit'} width={520} />
                            </Box>
                        </Box>
                        <Box
                            classnames={
                                'self-center order-2 md:order-3 pb-8 md:pb-0 text-center md:text-left mt-0 md:ml-[40px] w-full max-w-[243px]'
                            }
                        >
                            <Text classnames={'mb-5'}>
                                Start by connecting your payment account and making your first deposit.
                            </Text>
                            <Button
                                variant={'green'}
                                classnames={'w-full md:w-auto'}
                                onClick={() => handleAddFunds('overview_hero')}
                            >
                                Add funds
                            </Button>
                        </Box>
                    </Flex>
                </Card>
            </Box>
            <Box>
                <Card
                    shadow={'none'}
                    borderRadius={'lg'}
                    classnames={'bg-neutrals-100 border border-neutrals-200 text-center h-full relative pb-[80px] mb-5'}
                >
                    <Heading level={'h2'} classnames={'my-5 text-[32px] md:!text-[45px]'}>
                        {' '}
                        A Message from Our CEO
                    </Heading>
                    <Text classnames={'mb-8 text-center text-[18px]'}>
                        Learn how Vinovest makes wine investing simple.
                    </Text>
                    <VideoContainer
                        className={
                            'mx-auto w-[281px] md:w-[1024px] h-[485px] md:h-[576px] rounded-[10px] overflow-hidden'
                        }
                    >
                        <ReactPlayer
                            url={`https://player.vimeo.com/video/${videoID}?badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479&texttrack=en-x-autogen`}
                            config={{
                                vimeo: {
                                    playerOptions: {
                                        id: `${videoID}`,
                                        playsinline: true,
                                        autoplay: true,
                                        muted: false,
                                        controls: true,
                                        texttrack: 'en-x-autogen'
                                    }
                                }
                            }}
                            height={'100%'}
                            width={'100%'}
                            className={'rounded-[10px]'}
                            onPlay={() => PosthogHelper.captureEvent(AnalyticsEventNames.VideoStart, { type: 'wine' })}
                            onEnded={() => PosthogHelper.captureEvent(AnalyticsEventNames.VideoEnd, { type: 'wine' })}
                            light={
                                <Image
                                    src={videoThumb}
                                    alt={'Thumbnail'}
                                    width={thumbnailWidth}
                                    height={thumbnailHeight}
                                />
                            }
                        />
                    </VideoContainer>
                </Card>
            </Box>
            {bookACallSection}
            <Grid classnames={'grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 mb-5'}>
                <Box>
                    <Card
                        shadow={'none'}
                        borderRadius={'lg'}
                        classnames={'bg-neutrals-100 border border-neutrals-200 text-center h-full relative pb-[100px]'}
                    >
                        <Heading level={'h5'} classnames={'my-5 text-[32px] md:text-[36px] md:text-20'}>
                            How Wine Investing Works
                        </Heading>
                        <Image src={howWineInvestingWorks} alt={'How Wine Investing Works'} height={275} width={320} />
                        <List classnames={'mt-5 text-left max-w-[380px] mx-auto'}>
                            <ListItem classnames={'py-4 relative pl-[60px]'}>
                                <i
                                    className={
                                        'bg-platinum-800 absolute left-[0] top-[50%] translate-y-[-50%] text-white w-[35px] h-[35px] flex justify-center items-center rounded-[50px] not-italic font-roslindale text-[20px]'
                                    }
                                >
                                    1
                                </i>
                                <Text classnames={'max-w-[350px] m-auto'}>Get started with as little as $1,000.</Text>
                            </ListItem>
                            <ListItem classnames={'py-4 border-t border-neutrals-200 relative pl-[60px]'}>
                                <i
                                    className={
                                        'bg-platinum-800 absolute left-[0] top-[50%] translate-y-[-50%] text-white w-[35px] h-[35px] flex justify-center items-center rounded-[50px] not-italic font-roslindale text-[20px]'
                                    }
                                >
                                    2
                                </i>
                                <Text classnames={'max-w-[350px] m-auto'}>
                                    Invest in expertly selected, insured, and stored wine.
                                </Text>
                            </ListItem>
                            <ListItem classnames={'py-4 border-t border-neutrals-200 relative pl-[60px]'}>
                                <i
                                    className={
                                        'bg-platinum-800 absolute left-[0] top-[50%] translate-y-[-50%] text-white w-[35px] h-[35px] flex justify-center items-center rounded-[50px] not-italic font-roslindale text-[20px]'
                                    }
                                >
                                    3
                                </i>
                                <Text classnames={'max-w-[350px] m-auto'}>
                                    Track your growth over the next 7 to 10+ years.
                                </Text>
                            </ListItem>
                            <ListItem classnames={'py-4 border-t border-neutrals-200 relative pl-[60px]'}>
                                <i
                                    className={
                                        'bg-platinum-800 absolute left-[0] top-[50%] translate-y-[-50%] text-white w-[35px] h-[35px] flex justify-center items-center rounded-[50px] not-italic font-roslindale text-[20px]'
                                    }
                                >
                                    4
                                </i>
                                <Text classnames={'max-w-[350px] m-auto'}>
                                    Sell your wine when it reaches maturity.
                                </Text>
                            </ListItem>
                        </List>

                        <Button
                            variant={'green'}
                            classnames={'w-auto absolute bottom-[35px] left-[50%] translate-x-[-50%]'}
                            onClick={() => handleAddFunds('how_wine_investing_works')}
                        >
                            Add funds
                        </Button>
                    </Card>
                </Box>
                <Box>
                    <Card
                        shadow={'none'}
                        borderRadius={'lg'}
                        classnames={'bg-neutrals-100 border border-neutrals-200 text-center h-full relative pb-[100px]'}
                    >
                        <Heading level={'h5'} classnames={'my-5 text-[32px] md:text-[36px] md:text-20'}>
                            Why Vinovest?
                        </Heading>
                        <Image src={howVinovestWorks} alt={'How Vinovest Works'} height={275} width={320} />
                        <List classnames={'mt-5 text-left max-w-[380px] mx-auto'}>
                            <ListItem classnames={'py-4'}>
                                <Text classnames={'m-auto'}>$100M+ in assets under management</Text>
                            </ListItem>
                            <ListItem classnames={'py-4 border-t border-neutrals-200'}>
                                <Text classnames={'max-w-[300px]'}>100% ownership of your wine</Text>
                            </ListItem>
                            <ListItem classnames={'py-4 border-t border-neutrals-200'}>
                                <Text classnames={'max-w-[300px]'}>
                                    Trusted by 150,000 registered users in 40+ countries
                                </Text>
                            </ListItem>
                        </List>

                        <Button
                            variant={'green'}
                            classnames={'w-auto absolute bottom-[35px] left-[50%] translate-x-[-50%]'}
                            onClick={() => handleAddFunds('how_vinovest_works')}
                        >
                            Add funds
                        </Button>
                    </Card>
                </Box>
            </Grid>
            <Box classnames={'mb-5'}>
                <Card shadow={'none'} borderRadius={'lg'} classnames={'bg-neutrals-100 border border-neutrals-200 p-0'}>
                    <Flex classnames={'flex-col p-3 md:p-12 items-stretch'}>
                        <Box classnames={'w-full m-auto'}>
                            <Box classnames={'m-auto hidden text-center md:block'}>
                                <Heading level={'h2'} classnames={'my-5 normal-case md:text-[36px]'}>
                                    Still have questions?
                                </Heading>
                            </Box>
                        </Box>
                        <Box classnames={'max-w-[650px] mx-auto w-full'}>
                            <Faqs data={preFundedFAQs} />
                        </Box>
                        <Box classnames={'m-auto block text-center mt-12'}>
                            <Button
                                variant={'green'}
                                classnames={'w-auto'}
                                onClick={() => handleAddFunds('faq_section')}
                            >
                                Add funds
                            </Button>
                            <Text classnames={'mb-5 mt-12'}>Learn more on our FAQ page or speak with an advisor.</Text>
                        </Box>
                    </Flex>
                </Card>
            </Box>
            <TryWhiskeyCard />
        </>
    );
};

export const VideoContainer = styled.div`
    position: relative;
    .react-player__shadow {
        background: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 60%) !important;
        background-color: #fff !important;
        height: 75px;
        width: 75px;
    }
    .react-player__play-icon {
        border-color: transparent transparent transparent #000 !important;
    }
`;
