/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import dynamic from 'next/dynamic';

import GreenCheck from '~/assets/img/onboarding/green-check.svg';
import Lauren from '~/assets/img/onboarding/Lauren.png';
import { AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { PosthogHelper } from '~/helpers/PosthogHelper';
import { LocalStorageHelper } from '~/helpers/StorageHelper';
import { useTracking } from '~/hooks/tracking/useTracking';
import { useWindowSize } from '~/hooks/useWindowSize';
import Link from 'next/link';

const BookACallEmbed = dynamic(import('~/components/modules/BookACall/BookACallEmbed'));

interface Props {
    hasSkip?: boolean;
    useType?: 'whiskey' | 'wine';
}

export const PlanBookACallEmbed = ({ hasSkip = false, useType }: Props) => {
    const router = useRouter();
    const { captureEvent } = useTracking();
    const recommendedType: 'whiskey' | 'wine' =
        useType || LocalStorageHelper.getItem<'whiskey' | 'wine' | undefined>('recommendation-type') || 'whiskey';
    const redirectUrl: string = LocalStorageHelper.getItem('redirect-url') || '/whiskey/reserve';
    const { isMobile } = useWindowSize();

    const url =
        recommendedType === 'wine'
            ? (process.env.NEXT_PUBLIC_CALENDLY_WINE_EXPERTS as string)
            : (process.env.NEXT_PUBLIC_WHISKEYVEST_CALENDLY_LINK as string);

    const skipBooking = () => {
        PosthogHelper.captureEvent(AnalyticsEventNames.SkipCall);
    };
    useEffect(() => {
        captureEvent(AnalyticsEventNames.Calendly, { progress: 'start' });
    }, []);

    const onBooked = () => {
        void router.push(redirectUrl);
    };

    return (
        <>
            <CalendlyEmbedContainer>
                <CalendlyInfo>
                    <ProfileImg>
                        <Image
                            src={Lauren}
                            alt={`Lauren`}
                            width={isMobile ? '71' : '108'}
                            height={isMobile ? '71' : '108'}
                            className={'profile'}
                        />
                    </ProfileImg>
                    <CalendlyInfoHeading>Schedule an Advisory Call</CalendlyInfoHeading>
                    <CalendlyInfoDescription>
                        Based on your profile you qualify to schedule a free call with our team to:
                    </CalendlyInfoDescription>
                    <CalendlyInfoList>
                        <li>
                            <Icon>
                                <Image src={GreenCheck} alt={`Green Check`} />
                            </Icon>{' '}
                            Learn more about wine & whiskey to see if its the right fit for your portfolio.
                        </li>
                        <li>
                            <Icon>
                                <Image src={GreenCheck} alt={`Green Check`} />
                            </Icon>{' '}
                            Answer any questions you may have!
                        </li>
                    </CalendlyInfoList>
                    {hasSkip && (
                        <CalendlyInfoLink
                            href={redirectUrl}
                            onClick={skipBooking}
                            className={'!hidden md:!inline-flex'}
                        >
                            No Thanks, I'll Begin On My Own
                        </CalendlyInfoLink>
                    )}
                </CalendlyInfo>
                <CalendlyCalendar className={'min-w-[100%] md:min-w-0'} style={{ width: '100%', height: '600px' }}>
                    <BookACallEmbed
                        url={url}
                        onBooked={onBooked}
                        pageSettings={{ hideEventTypeDetails: true, hideGdprBanner: true }}
                    />
                </CalendlyCalendar>

                {hasSkip && (
                    <CalendlyInfoLink href={redirectUrl} onClick={skipBooking} className={'mx-auto md:!hidden'}>
                        No Thanks, I'll Begin On My Own
                    </CalendlyInfoLink>
                )}
            </CalendlyEmbedContainer>
        </>
    );
};

const CalendlyEmbedContainer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    @media screen and (min-width: 998px) {
        flex-flow: row;
        gap: 50px;
        margin-top: 50px;
    }
`;

const CalendlyInfo = styled.div`
    display: flex;
    flex-flow: column;
    max-width: 600px;
    padding-bottom: 20px;
    position: relative;
    @media screen and (min-width: 998px) {
        max-width: 400px;
    }
`;

const ProfileImg = styled.span`
    height: 71px;
    position: absolute;
    right: 0;
    top: 0;
    width: 71px;
    @media screen and (min-width: 998px) {
        font-size: 108px;
        height: 108px;
        max-width: 108px;
        width: 108px;
    }
`;

const CalendlyInfoHeading = styled.h3`
    font-family: var(--font-roslindale);
    font-size: 24px;
    max-width: 180px;
    @media screen and (min-width: 998px) {
        font-size: 36px;
        max-width: 280px;
    }
`;

const CalendlyInfoDescription = styled.p`
    font-size: 18px;
    margin: 40px 0 30px;
`;

const CalendlyInfoList = styled.ul`
    font-size: 16px;
    li {
        margin-bottom: 25px;
        padding-left: 35px;
        position: relative;
    }
`;

const Icon = styled.span`
    left: 0;
    position: absolute;
    top: 0;
`;

const CalendlyInfoLink = styled(Link)`
    align-items: center;
    border: 1px solid #b5b5b5;
    display: inline-flex;
    height: 60px;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 0;
    max-width: 330px;
    width: 100%;
    @media screen and (min-width: 998px) {
        margin-bottom: 0;
        margin-top: 40px;
    }
`;

const CalendlyCalendar = styled.div`
    background-color: #ffffff;
    border-bottom: 1px solid #b5b5b5;
    border-top: 1px solid #b5b5b5;
    margin-bottom: 40px;
    max-width: 95%;
    min-height: 630px;
    @media screen and (min-width: 998px) {
        border: 1px solid #b5b5b5;
        border-radius: 8px;
        max-width: 485px;
        min-height: 630px;
    }
    iframe {
        border-radius: 8px;
    }
`;
