import Image from 'next/legacy/image';

import TryWineDt from '~/assets/img/managed/whiskey-cross-sell.jpg';
import { AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { useTracking } from '~/hooks/tracking/useTracking';
import { useWindowSize } from '~/hooks/useWindowSize';
import { whiskeyRoutes } from '~/routes/whiskey';
import { Button } from '~/vinovest-design-system/components';

const brand = {
    subtext: 'Starting at $1,750',
    description: 'Just like wine, whiskey gets better with age. Expand your portfolio today.',
    btn_label: 'Invest in whiskey'
};

export const TryWhiskeyCard = () => {
    const { captureEvent } = useTracking();

    const handleInvestClick = () => {
        captureEvent(AnalyticsEventNames.SelectWhiskey);
    };

    const { isMobile } = useWindowSize();

    return (
        <div
            className={`flex bg-turtleGreen-800 w-full md:flex-row md:h-[383px] mt-[20px] mx-auto rounded-[8px] p-[25px] px-[40px] md:p-[40px] md:py-[50px] md:px-[70px] relative min-h-[450px] overflow-hidden`}
        >
            <div className={'relative z-[5] w-full max-w-[393px] text-center md:text-left'}>
                <p
                    className={
                        'font-roslindale text-brown-300 text-[24px] md:text-[36px] leading-[28px] md:leading-[54px] max-w-[590px] mx-auto'
                    }
                >
                    {brand.description}
                </p>
                <p className={'text-brown-300 text-[14px] my-6 md:my-8'}>{brand.subtext}</p>
                <div className={'flex flex-col w-full items-center md:items-start'}>
                    <Button
                        onClick={handleInvestClick}
                        href={whiskeyRoutes.reserve.href}
                        variant={'green'}
                        classnames={`w-[222px] h-[77px] font-vinovestmedium text-[16px] uppercase mb-[25px] md:mb-0 text-platinum-800 flex items-center justify-center`}
                    >
                        {brand.btn_label}
                    </Button>
                </div>
            </div>
            <div className={'absolute z-[0] left-[0] md:left-[auto] md:right-[50px] bottom-[-7px]'}>
                <Image src={TryWineDt} height={isMobile ? '279' : '450'} width={isMobile ? '344' : '660'} />
            </div>
        </div>
    );
};
